<template>
  <div
    class="form-no-messages"
    style="position:relative"
  >
    <v-container>
      <div
        v-if="$store.getters.getMobileModel !== 'PDA' && showQrScaner==='block'"
        class="qrcode-wrap"
      >
        <QrCodeScanComponent @scan-result="onPCodeScan" />
      </div>
      <v-row class="pb-1">
        <v-col
          class="pb-0 pt-0"
          :class="{'pr-0': $store.getters.getMobileModel !== 'PDA' }"
          :cols="$store.getters.getMobileModel !== 'PDA' ? 7 : 8"
        >
          <v-text-field
            id="input-qrkey"
            v-model="qrKey"
            label="파렛트번호"
            autocomplete="off"
            placeholder="QR 스캔"
            @keyup.enter.prevent="onEnterPcode"
          />
        </v-col>
        <v-col
          v-if="$store.getters.getMobileModel !== 'PDA'"
          class="pb-0 pt-4 pl-0"
          cols="1"
        >
          <v-icon
            @click="showQrScaner = showQrScaner === 'none' ? 'block' : 'none'"
          >
            mdi-camera
          </v-icon>
        </v-col>
        <v-col
          class="pb-0 pt-0"
          cols="4"
        >
          <v-text-field
            id="input-qty"
            v-model="inputQty"
            label="입고수량"
            autocomplete="off"
            type="number"
            @keyup.enter.prevent="onEnterQty"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col
          class="pb-0 pt-0"
          cols="6"
        >
          <v-autocomplete
            id="input-companyCode"
            ref="refCompanyCode"
            v-model="selectedRow.거래처코드"
            :items="companyInfo"
            label="구매처"
            :item-text="getCompanyName"
            item-value="거래처코드"
            auto-select-first
          />
        </v-col>
        <v-col
          class="pb-0 pt-0"
          cols="6"
        >
          <v-text-field
            id="input-lotNo"
            v-model="inputLotNo"
            autocomplete="off"
            label="롯트번호"
            type="number"
            @keyup.enter.prevent="onEnterLotNo"
          />
        </v-col>
      </v-row>
      <v-row
        class="mt-0 mb-0 pt-1"
      >
        <v-col
          cols="12"
        >
          <v-btn
            width="100%"
            color="primary"
            @click="onEnterLotNo"
          >
            입고 등록
          </v-btn>
        </v-col>
      </v-row>
      <v-text-field
        v-model="selectedRow.품번"
        label="품번"
        autocomplete="off"
        :readonly="true"
        filled
      />
      <v-text-field
        v-model="selectedRow.품명"
        label="품명"
        autocomplete="off"
        :readonly="true"
        filled
      />
      <v-row>
        <v-col
          cols="4"
        >
          <v-text-field
            :value="getUnitBom()"
            label="BOM단위"
            autocomplete="off"
            :readonly="true"
            filled
          />
        </v-col>
        <v-col
          cols="4"
        >
          <v-text-field
            :value="getUnitText()"
            label="주문단위"
            autocomplete="off"
            :readonly="true"
            filled
          />
        </v-col>
        <v-col
          cols="4"
        >
          <v-text-field
            v-model="selectedRow.변환배율"
            label="변환율"
            autocomplete="off"
            :readonly="true"
            filled
          />
        </v-col>
      </v-row>
      <div style="margin-top:10px; background-color:white;">
        <dx-data-grid
          ref="refGoodsInputGrid"
          :data-source="goodsInputListInfo"
          :show-borders="true"
          :show-row-lines="false"
          :show-column-lines="true"
          :hover-state-enabled="true"
          :allow-column-resizing="true"
          column-resizing-mode="widget"
          :allow-column-reordering="true"
          :row-alternation-enabled="true"
          :height="gridHeightSub"
          :focused-row-enabled="true"
          key-expr="발주번호"
          :focused-row-key.sync="focusedRowKey"
          @row-removed="onRowRemoved"
        >
          <DxEditing
            :allow-deleting="true"
            :allow-updating="false"
            :allow-adding="false"
            :use-icons="true"
            mode="row"
          />
          <DxColumn
            :allow-editing="false"
            caption="품번"
            data-field="품번"
          />
          <DxColumn
            :allow-editing="false"
            caption="입고수량"
            data-field="수량"
            format="#,##0"
          />
          <DxColumn
            width="50"
            :buttons="editButtons"
            type="buttons"
          />
          <DxSummary>
            <DxTotalItem
              value-format="#,##0"
              show-in-column="품번"
              display-format="{0} (건)"
            />
          </DxSummary>
          <dx-paging :enabled="false" />
          <dx-scrolling
            mode="virtual"
          />
          <dx-sorting mode="multiple" />
        </dx-data-grid>
      </div>
    </v-container>
  </div>
</template>
<script>
import DxDataGrid, { DxScrolling, DxColumn, DxEditing, DxPaging, DxSorting, DxSummary, DxTotalItem } from 'devextreme-vue/data-grid'
import AppLib from '../../share/AppLib'
import ConstDef from '../../../../MesSmartVue/src/share/ConstDef'
import QrCodeScanComponent from '../../components/QrCodeScanComponent'

export default {
  name: 'PurchaseDelivery',
  components: {
    DxDataGrid,
    DxScrolling,
    DxColumn,
    DxPaging,
    DxSorting,
    DxSummary,
    DxTotalItem,
    DxEditing,
    QrCodeScanComponent
  },
  props: {
    goodsType: {
      type: String,
      default: 'S0409'
    }
  },
  data: function () {
    return {
      isSaving: false,
      userInfo: null,
      systemParam: null,
      deliveryListInfo: [],
      focusedRowKey: null,
      selectedRow: {},
      focusedRowKeyOrder: null,
      inputQty: null,
      gridHeightSub: null,
      goodsInputListInfo: [],
      deleverydatetime: null,
      datePickerWidth: '350px',
      editButtons: ['delete'],
      unitLookup: null,
      purchaseInfoList: [],
      selectedPCode: null,
      inputLotNo: null,
      창고관리: null,
      companyInfo: [],
      // qrcode scan
      showQrScaner: 'none',
      qrKey: null
    }
  },
  created () {
    this.userInfo = this.$store.getters.getUserInfo
    if (this.userInfo === undefined || this.userInfo === null) {
      this.$router.push('/')
    }
    this.unitLookup = this.$store.getters.getBaseDataByType(ConstDef.단위)
    this.datePickerWidth = this.getDatePickerWidth()
    this.systemParam = this.$store.getters.getSysParam
    const where = this.$_.findWhere(this.systemParam, { key: '제품유효기간' })
    const value = this.$_.findWhere(this.$store.getters.getSysParam, { key: '창고관리' }).value
    this.창고관리 = value !== 'false'
    this.date = AppLib.calcDateFromToday(parseInt(where.value))
    this.refreshInputGoodsList()
  },
  mounted () {
    this.gridHeightSub = (this.$Q('html').height() - 432)
    window.addEventListener('resize', this.handleResize)
    this.initInput()
    this.purchaseInfoList = this.$store.getters.getPurchaseList(this.goodsType)
    this.companyInfo = this.$store.getters.getPartnersInfo
  },
  beforeDestroy: function () {
    window.removeEventListener('resize', this.handleResize)
  },
  updated () {
  },
  methods: {
    getDatePickerWidth () {
      return `${AppLib.getDatePickerWidth(this.$store)}px`
    },
    getText (row) {
      return `${row.품번}`
    },
    getCompanyName (row) {
      return `${row.거래처명}`
    },
    getUnitBom () {
      if (this.selectedRow.단위 === undefined) return
      return this.$_.findWhere(this.unitLookup, { 코드: this.selectedRow.단위 }).코드명
    },
    getUnitText () { // 구매단위
      if (this.selectedRow.구매단위 === undefined) return
      return this.$_.findWhere(this.unitLookup, { 코드: this.selectedRow.구매단위 }).코드명
    },
    handleResize (event) {
    },
    async addInventory () {
      if (this.isSaving) return
      this.deleveryDateTime = AppLib.getDeliveryTime(this.$store.getters.getSysParamByKey('재고관리기준시간'))
      let param = null
      const 발주번호 = AppLib.uuidSequential()
      let 단가 = 0
      this.isSaving = true
      await this.$_sp.runNoEncodeFindProc('spFindAllCostOrSaleByGoodsCodeAndSaleType', { 입출고구분: 1, 품번: this.selectedRow.품번, 기준일: AppLib.getNow() })
        .then((data) => {
          console.log(data)
          const 가격정보 = this.$_sp.MakeModel(data)
          this.isSaving = false
          if (가격정보.length > 0) {
            단가 = 가격정보[0].가격
          } else {
            this.$snotify.info('가격정보가 등록되어있지 않습니다. 추후 수정하여주세요.')
          }
        })
        .catch(error => {
          if (error.response.status === ConstDef.GENERRAL_ERROR) {
            this.$snotify.error(error.response.data.message)
          } else {
            this.$snotify.error(`에러코드 : ${error.response.status}`)
          }
        })
      if (this.isSaving === true) {
        this.isSaving = false
        return
      }
      param = [
        {
          발생일자: this.deleveryDateTime,
          입출고구분: this.$_.findWhere(ConstDef.PURCHASE_INOUT_CODE, { 코드명: '자재입고' }).코드,
          품번: this.selectedRow.품번,
          수량: Math.round(parseInt(this.inputQty) * this.selectedRow.변환배율),
          단가: 단가,
          lotNo: this.inputLotNo,
          입고업체: this.selectedRow.거래처코드,
          입고키: this.qrKey,
          발주번호: 발주번호,
          마감일자: this.deleveryDateTime,
          createid: this.userInfo.userName,
          updateid: this.userInfo.userName,
          createtime: AppLib.getNow()
        }
      ]
      // 납품예약 확인
      if (this.goodsType === ConstDef.외주직납품) {
        console.log(param[0].발생일자)
        const 납품일자 = AppLib.formatDateFromTimeStamp(param[0].발생일자)
        console.log(납품일자)
        await this.$_sp.runNoEncodeFindSqlProc('협력사거래내역서', { 품번: param[0].품번, 거래처코드: param[0].입고업체, 납품일자: 납품일자 })
          .then((data) => {
            const 납품예정품목 = this.$_sp.MakeModel(data)
            console.log(납품예정품목)
            if (납품예정품목.length > 0) {
              param[0].발주번호 = 납품예정품목[0].seqId
            }
          })
      }
      console.log(param)
      this.$_sp.runInsertSqlProc('창고수불', null, param)
        .then((data) => {
          this.isSaving = false
          this.focusedRowKey = param[0].발주번호
          this.goodsInputListInfo.push(param[0])
          this.deliveryOrderUpdate(param)
          this.$snotify.info('입고처리 되었습니다.')
          // this.insertTakeout(param)
          this.initInput()
        })
        .catch(error => {
          this.isSaving = false
          this.initInput()
          if (error.response.status === ConstDef.GENERRAL_ERROR) {
            this.$snotify.error(error.response.data.message)
          } else {
            this.$snotify.error(`에러코드 : ${error.response.status}`)
          }
        })
    },
    // 납품예정 업데이트
    deliveryOrderUpdate (param) {
      this.$_sp.runUpdateSqlProc('협력사거래내역서', ['seqId'], null, [{ seqId: param[0].발주번호, 납품확인: true, 실납품수량: param[0].수량 }])
        .then((data) => {
          console.log('입고예정 항목 업데이트 완료')
        })
    },
    initInput () {
      this.inputQty = null
      this.selectedRow = {}
      this.qrKey = null
      this.inputLotNo = null
      setTimeout(() => {
        this.$Q('#input-qrkey').focus()
      }, 10)
    },
    async onEnterPcode (e) {
      if (this.qrKey === null || this.qrKey.length === 0) {
        return
      }
      let split = null
      split = AppLib.base64DecodeSubstring(this.qrKey).split('/')
      if (split.length === 1) {
        this.$snotify.error('일련번호를 확인할 수 없습니다.')
        return
      }
      this.qrKey = parseInt(split[1])
      this.qrPCode = split[0].replace('?', ' ')
      let qrKeyCheck = true
      await this.$_sp.runNoEncodeFindSqlProc('창고수불', { 품번: this.qrPCode, 입고키: this.qrKey })
        .then((data) => {
          if (this.$_sp.MakeModel(data).length > 0) {
            qrKeyCheck = false
          }
        })
      if (qrKeyCheck === false) {
        this.$snotify.info('이미 입고처리된 품목입니다 다시 확인하여주세요.')
        this.initInput()
        return
      }
      const result = this.$_.where(this.purchaseInfoList, { 품번: this.qrPCode })
      if (result.length === 1) {
        const 자재유형 = this.$_.findWhere(this.$store.getters.getBaseDataByType(ConstDef.자재유형), { 코드: this.goodsType }).코드명
        if (result[0].자재유형 !== this.goodsType) {
          this.$snotify.info(`해당 품목은 ${자재유형}이 아닙니다.`)
          this.initInput()
          return
        }
        this.selectedRow = result[0]
        this.showQrScaner = 'none'
        if (this.selectedRow.파레트적재량 !== undefined && this.selectedRow.파레트적재량 !== null && this.selectedRow.파레트적재량 > 0) {
          this.inputQty = this.selectedRow.파레트적재량
          this.$Q('#input-qty').focus()
        }
      } else {
        this.$snotify.info('해당 품목을 검색 할 수 없습니다.')
        this.initInput()
      }
    },
    // 카메라 스캔 결과 이벤트
    onPCodeScan (result) {
      this.qrKey = result
      this.onEnterPcode(null)
    },
    onEnterQty (e) {
      if (this.inputQty === undefined || this.inputQty === null || !AppLib.isNumeric(this.inputQty)) return
      this.$Q('#input-lotNo').focus()
    },
    onEnterLotNo (e) {
      if (this.qrKey === null || this.qrKey.length === 0) {
        this.$snotify.error('파렛트정보를 스캔하여 주세요')
        return
      }
      if (this.inputQty === undefined || this.inputQty === null || !AppLib.isNumeric(this.inputQty)) {
        this.$snotify.error('입고 수량을 입력하여 주세요')
        return
      }
      this.$Q('#input-qrkey').focus()
      this.addInventory()
    },
    onRowRemoved (row) {
      this.$_sp.runDeleteSqlProc('창고수불', [{ 발생일자: row.data.발생일자 }])
        .then((data) => {
        })
        .catch(error => {
          this.isSaving = false
          if (error.response.status === ConstDef.GENERRAL_ERROR) {
            this.$snotify.error(error.response.data.message)
          } else {
            this.$snotify.error(`에러코드 : ${error.response.status}`)
          }
        })
    },
    refreshInputGoodsList () {
      const param = {
        입출고구분: ConstDef.자재입고,
        입고업체: null,
        userId: this.userInfo.userId
      }
      this.$_sp.runNoEncodeFindProc('spFindAllInputGoosListByDelNoAndToday', param)
        .then((data) => {
          this.goodsInputListInfo = this.$_sp.MakeModel(data)
        })
    },
    // datepicker
    onDateInput () {
      this.$refs.dialog.save(this.date)
      setTimeout(() => {
        this.$Q('#input-qrkey').focus()
      }, 200)
    }
  }
}
</script>

<style lang="scss">

</style>
